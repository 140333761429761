






















import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted
} from '@nuxtjs/composition-api';
import { useAttributeCollectionItems } from '@wemade-vsf/attribute-collection';
import type { AttributeCollectionItem } from '@wemade-vsf/magento-api';
import { SfHeading } from '@storefront-ui/vue';
import LoadWhenVisible from 'components/theme/utils/LoadWhenVisible.vue';

export default defineComponent({
  props: {
    displaytype: {
      type: String,
      default: 'grid'
    },
    showtype: {
      type: String,
      default: 'all'
    },
    sort: {
      type: String,
      default: 'item_id'
    },
    sortdir: {
      type: String,
      default: 'asc'
    },
    title: {
      type: String,
      default: ''
    },
    columns: {
      type: String,
      default: '4'
    },
    collectionId: {
      type: Number,
      default: 0
    },
    layout: {
      type: String,
      default: 'image'
    }
  },
  components: {
    LoadWhenVisible,
    SfHeading,
    AttributeCollectionItemGrid: () => import('components/attribute-collection/AttributeCollectionItemGrid.vue'),
    AttributeCollectionItemSlider: () => import('components/attribute-collection/AttributeCollectionItemSlider.vue')
  },
  setup (props) {
    const { loading, getItems } = useAttributeCollectionItems()
    const items = ref<AttributeCollectionItem[]|null>(null)

    async function loadCollection() {
      const query = {
        filter: {
          collection_id: { eq: `${props.collectionId}`},
          is_active: { eq: '1' },
          ...props.showtype === 'featured' ? { is_featured: { eq: '1' } } : {}
        },
        pageSize: 100,
        sort: { [props.sort]: props.sortdir === 'desc' ? 'DESC' : 'ASC' }
      }
      items.value = await getItems(query)
    }

    const root = ref(null);
    const isVisible = ref(false);
    
    let observer = null;
    if (process.browser && ('IntersectionObserver' in window)) {
      observer = new IntersectionObserver((entries) => {
        if (entries[0].intersectionRatio <= 0) return;
        observer.unobserve(root.value);
        isVisible.value = true;
        loadCollection()
      }, { rootMargin: '0px 0px 200px 0px' })
    }

    onMounted(() => {
      if (observer) {
        observer.observe(root.value);
      }
    })

    onUnmounted(() => {
      if (observer) {
        observer.unobserve(root.value);
      }
    })

    return {
      loading,
      items,
      root,
      isVisible
    }
  }
})
